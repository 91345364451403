.gradCircle6{
    width: 500px;
    height: 500px;
    background-color: #f4f1ff;
    border-radius: 50%;
    position: absolute;
    left: -10%;
    top: -20%;
    filter: blur(60px);
}
.arrow{
    position: absolute;
    left: 10%;
    top: 10%;
}
.gradCircle7{
    width: 500px;
    height: 500px;
    background-color: #f4f1ff;
    border-radius: 50%;
    position: absolute;
    right: -20%;
    bottom: -20%;
    filter: blur(60px);
}
.policy_content{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
