@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600&display=swap');

.content{
  margin-top: 100px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-left: 310px;
  /* padding: 30px;
  box-shadow: 0px 0px 5px #e0e0e0;
  border-radius: 5px; */
}
.css-19kzrtu {
  padding: 0px 20px !important;
}
.headline{
  position: relative;
}
.headline::after{
  content: '';
  position: absolute;
  display: block;
  width: 60px;
  height: 2px;
  top: 3;
  background-color: green;
}
.export-button{
  padding: 10px;
  background: green;
  color: #fff;
}

/* src/App.css */
.camera {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video, .canvas {
  width: 150px;
  height: 150px;
  border: 1px solid black;
  margin: 10px;
}

.canvas {
  display: none;
}

.canvas.hasPhoto {
  display: block;
}
