.inputField{
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #cccccc;
    margin-top: 5px;
    outline: none;
}
.inputField::placeholder{
    font-size: 15px;
}
.inputField:focus {
    border: 2px solid #6F50F7;
}
.iocn{
   position: absolute;
   right: 2%;
   top: 55%;
   transform: translate(-50%, -50%);
}